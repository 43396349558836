<template>
    <ion-page>
        <ion-content>
            <div class="main">
                <div class="topBackground">
                    <ion-text class="cWhite ion-text-center menuTitleFont">
                        <h2 class="ion-no-margin menuTitleFont" style="font-size: 24px;">Complètez les informations de votre pharmacie !</h2>
                    </ion-text>
                </div>
                <div class="maxWidth">
                    <ion-card>
                        <ion-card-header>
                            <ion-card-title>Commençons par les informations basiques</ion-card-title>
                        </ion-card-header>
                        <ion-card-content>
                            <ion-item>
                                <ion-label position="floating">Nom de la pharmacie</ion-label>
                                <ion-input v-model="info.name"></ion-input>
                            </ion-item>
                            
                            <ion-item>
                                <ion-label position="floating">Adresse</ion-label>
                                <ion-input v-model="road"></ion-input>
                            </ion-item>

                            <div style="display: flex; justify-content: space-around;">
                              <ion-item slot="start" lines="full" style="width:100%">
                                  <ion-label position="floating" style="width:100%">Code postal</ion-label>
                                  <ion-input v-model="zip"></ion-input>
                              </ion-item>
                              <ion-item slot="end" lines="full" style="width:100%">
                                  <ion-label position="floating">Ville</ion-label>
                                  <ion-input v-model="city"></ion-input>
                              </ion-item>
                              
                              
                            </div>
                            
                            <!-- <ion-item>
                                <ion-label position="floating">Typologie de la pharmacie</ion-label>
                                <ion-select interface="popover" placeholder="Veuillez choisir" v-model="info.typology">
                                  <ion-select-option value="district">Quartier</ion-select-option>
                                  <ion-select-option value="downtown">Centre Ville</ion-select-option>
                                  <ion-select-option value="mall">Centre Commercial</ion-select-option>
                                </ion-select>
                            </ion-item> -->
                          <ion-item>
                            <ion-label position="floating">Votre Logiciel utilisé</ion-label>
                            <ion-select interface="alert" cancel-text="" ok-text="Valider" placeholder="Veuillez choisir" v-model="info.logiciel">
                              <ion-select-option v-for="software in softwares" :key="software" :value="software">{{ software }}</ion-select-option>
                            </ion-select>
                          </ion-item>
                          <ion-item>
                            <ion-label position="floating">Présence d'un parking ?</ion-label>
                            <ion-select interface="popover" placeholder="Veuillez choisir" v-model="info.parking">
                              <ion-select-option value="payable">Parking payant</ion-select-option>
                              <ion-select-option value="free">Parking gratuit</ion-select-option>
                              <ion-select-option value="none">Pas de parking</ion-select-option>
                            </ion-select>
                          </ion-item>
                          <ion-item>
                            <ion-label position="floating">Comment venir dans votre pharmacie ?</ion-label>
                            <ion-select interface="alert" placeholder="Veuillez choisir" v-model="info.transport">
                              <ion-select-option value="car">Voiture</ion-select-option>
                              <ion-select-option value="transport">Transports en commun</ion-select-option>
                              <ion-select-option value="both">Voiture & Transports en commun</ion-select-option>
                            </ion-select>
                          </ion-item>
                        </ion-card-content>
                    </ion-card>

                    <ion-card class="ion-margin-vertical">
                        <ion-card-header>
                            <ion-card-title>Dites-nous en un peu plus</ion-card-title>
                        </ion-card-header>
                        <ion-card-content>

                            <ion-list>
                              <ion-text class="ion-text-start" >
                                <p class="yes-no-label">
                                  Est-ce que votre pharmacie est équipée d'une salle de repos avec un micro-onde où l'intérimaire peut rester pendant les pauses ?
                                </p>
                              </ion-text>
                              <ion-radio-group :value="info.has_rest_room" v-model="info.has_rest_room">
                                  <div style="width: 100%">
                                    <ion-item>
                                      <ion-radio :value="true" mode="md" slot="end"></ion-radio>
                                      <ion-label>Oui</ion-label>
                                    </ion-item>
                                    <ion-item>
                                      <ion-radio :value="false" mode="md" slot="end"></ion-radio>
                                      <ion-label>Non</ion-label>
                                    </ion-item>
                                  </div>
                              </ion-radio-group>
                            </ion-list>

                          <ion-list>
                            <ion-text>
                              <p class="yes-no-label">
                                Est-ce que votre pharmacie est robotisée ?
                              </p>
                            </ion-text>
                            <ion-radio-group :value="info.has_robots" v-model="info.has_robots">
                              <div style="width: 100%">
                                <ion-item>
                                  <ion-radio :value="true" mode="md" slot="end"></ion-radio>
                                  <ion-label>Oui</ion-label>
                                </ion-item>
                                <ion-item>
                                  <ion-radio :value="false" mode="md" slot="end"></ion-radio>
                                  <ion-label>Non</ion-label>
                                </ion-item>
                              </div>
                            </ion-radio-group>
                          </ion-list>

                          <ion-list>
                            <ion-text>
                              <p class="yes-no-label">
                                Est-ce qu'il y a des restaurants accessibles à côté de votre pharmacie ?
                              </p>
                            </ion-text>
                            <ion-radio-group :value="info.has_restaurant_near" v-model="info.has_restaurant_near">
                              <div style="width: 100%">
                                <ion-item>
                                  <ion-radio :value="true" mode="md" slot="end"></ion-radio>
                                  <ion-label>Oui</ion-label>
                                </ion-item>
                                <ion-item>
                                  <ion-radio :value="false" mode="md" slot="end"></ion-radio>
                                  <ion-label>Non</ion-label>
                                </ion-item>
                              </div>
                            </ion-radio-group>
                          </ion-list>

                          <!-- <ion-list>
                            <ion-text>
                              <p class="yes-no-label">
                                Est-ce que vous faites les TRODS ?
                              </p>
                            </ion-text>
                            <ion-radio-group :value="info.has_trods" v-model="info.has_trods">
                              <div style="width: 100%">
                                <ion-item>
                                  <ion-radio :value="true" mode="md" slot="end"></ion-radio>
                                  <ion-label>Oui</ion-label>
                                </ion-item>
                                <ion-item>
                                  <ion-radio :value="false" mode="md" slot="end"></ion-radio>
                                  <ion-label>Non</ion-label>
                                </ion-item>
                              </div>
                            </ion-radio-group>
                          </ion-list> -->

                          <!-- <ion-list>
                            <ion-text>
                              <p class="yes-no-label">
                                Est-ce qu'il y a une borne de télé consultation dans votre pharmacie ?
                              </p>
                            </ion-text>
                            <ion-radio-group :value="info.has_teleconsultation_booth" v-model="info.has_teleconsultation_booth">
                              <div style="width: 100%">
                                <ion-item>
                                  <ion-radio :value="true" mode="md" slot="end"></ion-radio>
                                  <ion-label>Oui</ion-label>
                                </ion-item>
                                <ion-item>
                                  <ion-radio :value="false" mode="md" slot="end"></ion-radio>
                                  <ion-label>Non</ion-label>
                                </ion-item>
                              </div>
                            </ion-radio-group>
                          </ion-list> -->
                        </ion-card-content>
                    </ion-card>
                    <div class="error-message" v-if="flashMessages.length > 0">
                        <ion-card :key="msg" v-for="msg in flashMessages">
                            {{ msg }}
                        </ion-card>
                    </div>
                    <ion-button @click="validate" color="success">
                        VALIDER
                    </ion-button>

                    <ion-button @click="goDashboard" color="light">
                        TABLEAU DE BORD
                    </ion-button>
                </div>
                <div class="footerBackground" v-if="!isDesktop">
                    <img class="ion-margin-bottom logoFooter" src="../../../public/assets/icon/logo-alloo-pharma.svg" />
                </div>
                <div class="footerNotMobile" v-else>
                    <img class="ion-margin-bottom logoFooter"
                         src="../../../public/assets/icon/alloopharma-logo.png" />
                </div>
            </div>
        </ion-content>
    </ion-page>
</template>

<script>
  import store from '@/store'
  import router from '@/router'
  import {
    IonButton,
    IonContent,
    IonInput,
    IonItem,
    IonLabel,
    IonList,
    IonPage,
    IonRadio,
    IonRadioGroup,
    IonText,
    IonSelect,
    IonSelectOption, IonCard, IonCardContent, IonCardHeader, IonCardTitle,
  } from '@ionic/vue';
  import { getSoftwares, updatePharmacy } from "../../services/api";

  export default {
    name: 'InfoPharmacy',
    components: {
      IonContent, IonPage, IonButton, IonRadioGroup, IonRadio, IonCard, IonCardContent, IonCardHeader, IonCardTitle,
      IonLabel, IonItem, IonText, IonInput, IonList, IonSelect, IonSelectOption,
    },
    data() {
      return {
        flashMessages: [],
        road: null,
        city: null,
        zip: null,
        info: {
          name: null,
          address: null,
          typology: null,
          logiciel: null,
          parking: null,
          transport: null,
          has_rest_room: null,
          has_robots: null,
          has_restaurant_near: null,
          has_trods: null,
          has_teleconsultation_booth: null,
        },
      }
    },
    mounted() {
      const pharmacy = store.getters.user.pharmacy;
      this.info.name = pharmacy?.name || null;
      this.info.address = pharmacy?.address || null;
      this.info.logiciel = pharmacy?.logiciel || null;
      this.info.transport = pharmacy?.transport || null;
      this.info.parking = pharmacy?.parking || null;
      this.info.typology = pharmacy?.typology || "downtown";
      this.info.has_robots = pharmacy?.hasRobots || null;
      this.info.has_restaurant_near = pharmacy?.hasRestaurantNear || null;
      this.info.has_trods = pharmacy?.hasTrods || true;
      this.info.has_teleconsultation_booth = pharmacy?.hasTeleconsultationBooth || false;
      this.info.has_rest_room = pharmacy?.hasRestRoom || null;
    },
    computed: {
      isDesktop() {
        return store.getters.isDesktop;
      },
      softwares()  {
        return store.getters.softwares;
      },
    },
    methods: {
      removeErrorMsg() {
        setTimeout(() => this.flashMessages = [], 10000);
      },
      validate() {
        this.onUpdate();
      },
      onUpdate() {
        console.log(this.info)
        this.info.address = this.road + ", " +this.zip + " "  + this.city
        if (Object.values(this.info).includes(null)) {
          this.removeErrorMsg();
          this.flashMessages = ["Vous n'avez pas renseigné tous les champs 😅"];
          return;
        }
        updatePharmacy(this.info).then(() => {
          this.flashMessages = ["Tous est bon, vous pouvez retourner sur le tableau  de bord 😊"];
          this.removeErrorMsg();
        }).catch((e) => {
          this.flashMessages = e.response.data.errors.full_messages;
          this.removeErrorMsg();
        });
      },
      goDashboard() {
        router.push("/tableau-bord")
      }
    },
    ionViewWillEnter() {
      getSoftwares();
      window.$crisp.push(["do", "chat:show"])
    }
  }
</script>
<style scoped>
 * {
        color: black;
        font-family: SFProTextMedium;
    }

    h1 {
        padding: 50px;
        margin-top: -20px
    }

    ion-label {
        margin: 5px;
    }



    h2 {
        padding: 50px;
        color: white;
    }

    h3 {
        font-size: 16px;
    }

    ion-card-content {
        padding: 0px
    }

</style>
